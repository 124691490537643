import React from 'react';
import EmbedWidget from './EmbedWidget';

function App() {
  return (
    <div className="App">
      <EmbedWidget />
    </div>
  );
}

export default App;
